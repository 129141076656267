
.opsunizerBackground {
    background: url($common_dir + "/images/opsunizer-page-background.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 65px;
    padding-bottom: 80px;
}

.opsunizerCtaA {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: url($common_dir + "/images/opsunizer-background-2.jpg") no-repeat center center;
    background-size: cover;
    padding: 0 60px 55px 35px;
}

.opsunizerCtaA-titleBlock { width: 55%; }

.opsunizerCtaA-surtitle {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #d4ac55;
    text-transform: uppercase;
    padding-left: 20px;
    padding-top: 50px;
    border-left: 2px solid #fff;
    margin-left: 38%;
    margin-bottom: 3px;
}

.opsunizerCtaA-title img { width: 90%; }

.opsunizerCtaA-infoBlock {
    width: 27%;
    margin-left: auto;
    padding-top: 35px;
    margin-right: 15px;
}

.opsunizerCtaA-text {
    text-shadow: 0 0 8px #192842;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 1px;
    color: #50a9ff;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.opsunizerCtaA-smallButton {
    @extend .button-blue;
}

.opsunizerCtaA-advantages {
    display: flex;
    width: 65%;
    margin-top: 50px;
}

.opsunizerCtaA-advantage {
    width: 20%;
    padding: 0 5px;
}

.opsunizerCtaA-advantageImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 102px;
    margin-bottom: 14px;

    img {
        flex: 0 0 auto;
        margin: auto;
    }
}

.opsunizerCtaA-advantageName {
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.7;
    text-align: center;
    color: #d4ac55;
    text-transform: uppercase;

    strong { font-weight: 700; }
}

.opsunizerCtaA-bigButton {
    @extend .button-big;
    align-self: flex-end;
    margin-left: auto;
    width: auto;
}

@include media-breakpoint-down(md) {
    .opsunizerCtaA { padding: 0 15px 15px; }

    .opsunizerCtaA-titleBlock { width: 65%; }

    .opsunizerCtaA-surtitle { margin-left: 31%; }

    .opsunizerCtaA-infoBlock {
        width: 33%;
        margin-right: 0;
    }

    .opsunizerCtaA-advantages { width: 100%; }

    .opsunizerCtaA-bigButton { margin-top: 1em; }
}

@include media-breakpoint-down(sm) {
    .opsunizerCtaA-titleBlock { width: 57%; }

    .opsunizerCtaA-surtitle {
        margin-left: 0;
        font-size: 20px;
    }

    .opsunizerCtaA-infoBlock { width: 43%; }
}

@include media-breakpoint-down(xs) {
    .opsunizerBackground { padding-top: 24px; }

    .opsunizerCtaA-titleBlock { width: 100%; }

    .opsunizerCtaA-infoBlock { width: 100%; }

    .opsunizerCtaA-advantages {
        flex-wrap: wrap;
        justify-content: center;
    }

    .opsunizerCtaA-advantage { width: 33.33%; }

    .opsunizerCtaA-advantageName { font-size: 10px; }

    .opsunizerCtaA-bigButton { margin-right: auto; }
}
