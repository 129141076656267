
.formCard {
    background: #f4f4f4;
    padding: 30px;
    width: 100%;
    max-width: 350px;

    .publicForm-success {
        background: none;
        border: 0;
        padding: 0;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.6;
        color: #404040;
        margin-top: 70px;
    }

    .publicForm-successTitle {
        font-family: Oswald;
        font-size: 26px;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 0.8px;
        color: $main-color;
        text-transform: uppercase;
        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 80%;
            height: 2px;
            background: $main-color;
        }
    }
}

.formCard-title {
    font-family: Oswald;
    font-size: 22px;
    line-height: 1.6;
    letter-spacing: 0.75px;
    color: #3c3c3c;
    text-transform: uppercase;
}

.formCard-text {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.6;
    color: #313131;
}

@include media-breakpoint-down(md) {
    .formCard {
        padding: 15px;
        max-width: 300px;
    }
}

@include media-breakpoint-down(sm) {
    .formCard { margin: 20px auto 0; }
}

@include media-breakpoint-down(xs) {

}
