
.imarcomSlideshow {
    height: 600px;
    background: #0c457c;
}

.imarcomSlideshow-slides {
    height: 100%;
    position: relative;
    z-index: 1;
}

.imarcomSlideshow-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.4s;

    &:not(.imarcomSlideshow-slide-active) * { transition: none !important; }
}

.imarcomSlideshow-slide-active {
    opacity: 1;
    z-index: 1;
}

.imarcomSlideshow-slideContainer {
    @extend .container;

    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.imarcomSlideshow-slideContent {
    position: absolute;
    top: 0;
    left: $grid-gutter-width/2;
    right: $grid-gutter-width/2;
    height: 100%;
    overflow: hidden;
}

.imarcomSlideshow-leftColumn {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 36%;
        height: 723px;
        width: 2px;
        background: $main-color;
        transform: translateY(100%);
    }

    &.animate:after {
        transform: translateY(0);
        transition: transform 1.2s;
    }
}

.imarcomSlideshow-slideMainContent {
    max-width: 400px;
    position: relative;
    transform: translateX(-50%);
    left: 50%;

    &.animate {
        transform: translateX(0);
        left: 0;
        transition: transform 0.5s, left 0.5s;
    }
}

.imarcomSlideshow-slideImage {
    opacity: 0;
    max-width: 100%;

    &:not(:last-child) { margin-bottom: 2em; }

    &.animate {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.imarcomSlideshow-slideTitle {
    font-size: 17px;
    line-height: 1.6;
    letter-spacing: 1.1px;
    color: #fff;
    text-transform: uppercase;
    opacity: 0;
    transform: translateX(5%);

    &:not(:last-child) { margin-bottom: 0.7em; }

    &.animate {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.5s;
    }
}

.imarcomSlideshow-slideParagraph {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 1px;
    color: #50a9ff;
    text-transform: uppercase;
    opacity: 0;
    transform: translateX(5%);

    &:not(:last-child) { margin-bottom: 1.2em; }

    > *:last-child { margin-bottom: 0; }

    &.animate {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.5s;
    }
}

.imarcomSlideshow-slideButton {
    @extend .button-big;
    opacity: 0;
    transform: translateX(5%);

    &.animate {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.5s;
    }
}

.imarcomSlideshow-slideTexts {
    position: absolute;
    top: 50%;
    right: 0;
    width: 30%;
    transform: translateY(-50%);
}

.imarcomSlideshow-slideText {
    position: absolute;
    top: 0;
    left: 0;
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #f5c142;
    text-transform: uppercase;
    transition: opacity 1s;
    opacity: 0;

    &:first-child { position: relative; }

    > *:last-child { margin-bottom: 0; }
}

.imarcomSlideshow-slideText-active { opacity: 1; }

.imarcomSlideshow-slidePhotos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.imarcomSlideshow-slidePhoto {
    opacity: 0;
    transition: opacity 1.5s;
}

.imarcomSlideshow-slidePhoto-active { opacity: 1; }

.imarcomSlideshow-menuContainer {
    @extend .container;

    position: relative;
    z-index: 2;
}

.imarcomSlideshow-menu {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.imarcomSlideshow-menuLink {
    font-size: 0;
    width: 40px;
    height: 40px;
    display: block;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 14px;
        left: 14px;
        right: 14px;
        bottom: 14px;
        border-radius: 50%;
        background: $main-color;
        transition: background 0.3s;
    }

    &:hover:before,
    .imarcomSlideshow-menuElement-active &:before { background: #fff; }
}

@include media-breakpoint-down(sm) {
    .imarcomSlideshow-slideContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .imarcomSlideshow-leftColumn {
        position: relative;
        top: auto;
        transform: none;
        padding-bottom: 20px;
        margin-bottom: 20px;

        &:after {
            width: 0;
            height: 2px;
            top: auto;
            bottom: 0;
            left: 0;
            right: auto;
            transform: none !important;
        }

        &.animate:after {
            width: 100%;
            transition: width 1.2s;
        }
    }

    .imarcomSlideshow-slideMainContent {
        max-width: none;
        left: 0 !important;
        transform: none !important;
    }

    .imarcomSlideshow-slideTexts {
        position: relative;
        top: auto;
        left: 0;
        right: auto;
        width: 100%;
        transform: none;
    }
}

@include media-breakpoint-down(xs) {
    .imarcomSlideshow { height: 550px; }

    .imarcomSlideshow-slideContent {
        left: 24px;
        right: 24px;
    }

    .imarcomSlideshow-slideTitle {
        font-size: 14px;
        line-height: 1.3;
    }

    .imarcomSlideshow-slideParagraph {
        font-size: 12px;
        line-height: 1.3;
    }

    .imarcomSlideshow-slideButton { font-size: 14px; }

    .imarcomSlideshow-slideText {
        font-size: 17px;
        line-height: 1.4;
    }
}
