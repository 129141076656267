
.imarcomGmap-maps {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.imarcomGmap-container {
    width: 33%;
    position: relative;
}

.imarcomGmap {
    display: block;
    width: 100%;
    height: 350px;
    margin-bottom: 3em;

    p:last-child { margin-bottom: 0; }
}

.imarcomGmap-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
}

.imarcomGmap-markerName {
    font-family: Oswald;
    font-size: 20px;
    letter-spacing: 0.7px;
    color: #404040;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}

.imarcomGmap-markerInfos {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.imarcomGmap-markerAddress {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.9;
    color: #404040;
    min-height: 80px;

    > *:last-child { margin-bottom: 0; }
}

.imarcomGmap-markerEmail {
    display: block;
    font-family: Roboto;
    font-size: 14px;
    color: #404040;
    transition: color 0.3s;

    &:hover {
        color: $main-color;
        text-decoration: underline;
    }
}

.imarcomGmap-markerPhone {
    display: block;
    font-family: Roboto;
    font-size: 17px;
    color: #404040;
    cursor: default;

    &:hover {
        color: #404040;
        text-decoration: none;
    }
}

@include media-breakpoint-down(sm) {
    .imarcomGmap-maps { display: block; }

    .imarcomGmap-container {
        width: 100%;

        + .imarcomGmap-container { margin-top: 50px; }
    }

    .imarcomGmap { margin-bottom: 1.5em; }
}

@include media-breakpoint-down(xs) {

}
