
.footer {
    /* max-width: 1366px; */
    margin: 0 auto;
    background: #252525;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.footer-column {
    flex: 0 0 auto;
    width: 30%;
}

.footer-social { margin-top: 2.3em; }

.footer-socialTitle {
    font-size: 10px;
    letter-spacing: 2.7px;
    color: #848484;
    text-transform: uppercase;
}

.footer-socialList {
    display: flex;
    justify-content: flex-start;
    flex: 1 1 auto;
    margin-top: 0.6em;
    margin-left: -6px;
}

.footer-socialElement {
    color: #fff;
    opacity: 0.3;
    font-size: 18px;
    padding: 0 9px;
    height: 20px;
    line-height: 20px;
    transition: opacity 0.3s;

    &:hover {
        text-decoration: none;
        color: #fff;
        opacity: 1;
    }
}

.footer-member { margin-top: 3.3em; }

.footer-memberTitle {
    font-size: 10px;
    letter-spacing: 2.7px;
    color: #848484;
    text-transform: uppercase;
}

.footer-memberList {
    display: flex;
    align-items: center;
    margin-top: 1.3em;
}

.footer-memberElement {
    height: 40px;

    + .footer-memberElement { margin-left: 2.5em; }
}

.footer-copyright {
    font-family: Roboto;
    font-size: 11px;
    color: #9d9d9d;
    font-weight: 300;
    width: 30%;
    line-height: 1.1;
}

.footer-largeColumn {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
}

.footer-menu {
    margin-top: 1em;

    &:last-child { margin-left: 10%; }
}

.footer-menuTitle {
    font-family: Oswald;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 1.5px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    margin: 1.45em 0;

    &:hover { color: #fff }
}

.footer-menuElements {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: -0.6em;

    ul {
        padding: 0 0 0 20px;
        list-style: none;
    }
}

.footer-menuElement {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    color: #9d9d9d;
    display: inline-block;
    padding: 6px 0;
    transition: color 0.3s;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.footer-language {
    font-size: 11px;
    letter-spacing: 2.1px;
    color: #848484;
    text-transform: uppercase;
    transition: color 0.3s;
    width: 143px;
    text-align: center;
    margin-left: auto;

    &:hover {
        text-decoration: none;
        color: $main-color;
        border-color: $main-color;
    }
}

.footer-terms {
    @extend .footer-menuElement;
    font-size: 11px;
    line-height: 1.1;
}

.footer-ctas {
    display: flex;
    flex-direction: column;
    width: 143px;
    margin-top: 1.3em;
}

.footer-cta {
    white-space: nowrap;

    + .footer-cta { margin-top: 10px; }
}

.footer-contact {
    margin-top: 2.4em;
    text-align: center;
}

.footer-contactLabel {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1.8px;
    color: #fff;
    text-transform: uppercase;
}

.footer-contactLink {
    font-family: Oswald;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: $main-color;
    cursor: default;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}

.footer-bottomRow {
    background: #1f1f1f;
    margin-top: 0.5em;
}

.footer-bottomRowContent {
    display: flex;
    align-items: center;
    min-height: 40px;
}

@include media-breakpoint-only(md) {
    .footer-memberList { display: block; }

    .footer-memberElement + .footer-memberElement {
        display: block;
        margin-left: 0;
        margin-top: 1em;
    }
}

@include media-breakpoint-down(md) {
    .footer-column {
        width: auto;
        margin-right: 15px;
    }

    .footer-menu:last-child { margin-left: 0; }

    .footer-copyright {
        width: auto;
        margin-right: 15px;
    }
}

@include media-breakpoint-down(sm) {
    .footer-content { flex-wrap: wrap; }

    .footer-largeColumn { order: 1; }

    .footer-column {
        order: 2;
        padding-right: 143px;
    }

    .footer-menu:last-child {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@include media-breakpoint-down(xs) {
    .footer-memberList { display: block; }

    .footer-menuElements { margin-right: 12px; }

    .footer-memberElement + .footer-memberElement {
        display: block;
        margin-left: 0;
        margin-top: 1em;
    }
}
