
/* NAV */

.cbp-l-filters-button {
    margin-bottom: 50px;
    font-size: 0;
    margin-left: -3px;
    margin-right: -3px;

    + .cbp-l-filters-button { margin-top: -50px; }

    .cbp-filter-item {
        margin: 3px;
        padding: 1px 13px;
        color: #9d9d9d;
        font-size: 13px;
        border-radius: 3px;
        font-family: Roboto;
        letter-spacing: 0.46px;
        background-color: #f1f1f1;
        display: inline-flex;
        align-items: center;
        border: 0;

        &:first-child { margin-left: 3px; }
        &:last-child { margin-right: 3px; }

        &:after {
            content: '+';
            display: block;
            width: 15px;
            height: 15px;
            line-height: 13px;
            text-align: center;
            border-radius: 50%;
            border: solid 1px #9d9d9d;
            margin-left: 6px;
        }

        &:hover {
            color: #525252;
            background-color: #ededed;

            &:after { border: solid 1px #525252; }
        }

        &.cbp-filter-item-active {
            color: #fff;
            background-color: #d4ac55;

            &:after {
                content: '-';
                font-size: 20px;
                border-color: #fff;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        text-align: left;
    }
}

.cbp { margin-bottom: 93px; }

/* PROJECTS ITEMS */

.grid-mosaic-projects {

    .cbp .cbp-item { border: 1px solid #eee; }

    .cbp-caption-activeWrap {
        top: auto;
        bottom: 0;
        height: auto;
        padding: 20px 9.5%;
        color: #fff;
        background-color: #252525;
        background-color: rgba(37, 37, 37, 0.9);
    }

    .small .cbp-caption-activeWrap { display: block; }

    .medium .cbp-caption-activeWrap {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .caption-title {
        font-family: Oswald;
        font-size: 22px;
        font-weight: 300;
        line-height: 2.45;
    }

    .caption-text {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.58px;
    }

    .projectHeader-feature {
        white-space: nowrap;
        margin-top: 15px;
    }
    
    .projectHeader-featureLabel { width: 35px; }

    .caption-bt {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 12px 30px 12px 19px;
        margin: 15px 0 0 5px;
        font-family: Oswald;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.5px;
        color: #d4ac55;
        border-radius: 4px;
        border: solid 1px #d4ac55;

        &:after,
        &:before {
            content: ">";
            position: absolute;
            top: 50%;
            right: 10px;
            width: 0px;
            height: 0px;
            font-size: 0;
            line-height: 0;
        }

        &:before {
            margin-top: -7px;
            border: 7px solid transparent;
            border-right-width: 0;
            border-left-color: #d4ac55;
            z-index: 1;
        }

        &:after {
            margin-top: -4px;
            margin-right: 2px;
            border: 4px solid transparent;
            border-right-width: 0;
            border-left-color: #3a3a3a;
            z-index: 2;
        }

        &:hover:after { border-left-color: #d4ac55; }

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .cbp-caption-activeWrap { opacity: 1; }
    }

    @include media-breakpoint-down(xs) {
        .cbp-caption-activeWrap {
            display: block;
            top: 0;
            bottom: auto;
        }
    }
}



/* NEWS ITEMS */

.grid-mosaic-news {

    .caption-link {
        display: block;
        position: relative;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #efefef;
            mix-blend-mode: multiply;
        }
    }

    .caption-tags {
        position: absolute;
        top: -2px;
        left: 13px;
        right: 13px;
        display: flex;
        flex-wrap: wrap;
    }

    .caption-tag {
        font-size: 10px;
        letter-spacing: 0.4px;
        color: #fff;
        text-transform: uppercase;
        background: #404040;
        padding: 8px 14px 5px 14px;
        margin: 2px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .caption-text {
        padding: 5px 9.5% 18px 9.5%;
        color: #000;
    }

    .caption-date {
        color: #a6a6a6;
        font-size: 9px;
        font-weight: 500;
        font-family: Roboto;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        transition: color 0.3s;
    }

    .caption-title {
        color: #404040;
        font-size: 19px;
        font-weight: 300;
        line-height: 1.1;
        font-family: Oswald;
        transition: color 0.3s;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 42px;
    }

    .caption-summary {
        font-family: Roboto;
        font-size: 11px;
        line-height: 1.5;
        color: #404040;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.cbp-lazyload:after {
    animation: none;
    border: 0;
    border-radius: 0;
}
