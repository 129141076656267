
.opsunizerCtaB {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    padding: 3.5%;
    background: url($common_dir + "/images/opsunizer-cta-b-background-2.jpg") no-repeat center center;
    background-size: cover;
    margin-bottom: 3.5em;
}

.opsunizerCtaB-texts { width: 24%; }

.opsunizerCtaB-title {
    max-width: 200px;
    margin-bottom: 37px;

    img { width: 100%; }
}

.opsunizerCtaB-subtitle {
    font-family: Oswald;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 1.2px;
    text-align: right;
    color: #9d9d9d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.opsunizerCtaB-icons {
    display: flex;
    justify-content: space-between;
    width: 47%;
}

.opsunizerCtaB-iconImage {
    height: 42px;
    margin: 0 auto 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    img { height: 32px; }
}

.opsunizerCtaB-iconImage-taller img { height: 42px; }

.opsunizerCtaB-iconName {
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.7;
    text-align: center;
    color: $main-color;
    text-transform: uppercase;
    max-width: 90px;
    margin: 0 auto;
}

.opsunizerCtaB-buttons { width: 20%; }

.opsunizerCtaB-learnMore {
    display: flex;
    align-items: flex-end;
}

.opsunizerCtaB-learnMoreText {
    text-shadow: 0 0 8px #192842;
    font-family: Roboto;
    font-size: 11px;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: #50a9ff;
    text-transform: uppercase;
}

.opsunizerCtaB-learnMoreButton {
    display: block;
    border: 1px solid #50a9ff;
    color: #50a9ff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    margin-left: 10px;
    flex: 0 0 auto;
    transition: color 0.3s, border-color 0.3s;

    &:hover {
        text-decoration: none;
        color: #fff;
        border-color: #fff;
    }
}

.opsunizerCtaB-button {
    @extend .button-big;
    margin-top: 35px;
    width: 100%;
}

@include media-breakpoint-down(lg) {
    .opsunizerCtaB { padding: 15px; }

    .opsunizerCtaB-texts { width: 24%; }

    .opsunizerCtaB-icons { width: 50%; }

    .opsunizerCtaB-buttons { width: 24%; }
}

@include media-breakpoint-down(md) {
    .opsunizerCtaB { flex-wrap: wrap; }

    .opsunizerCtaB-texts {
        order: 1;
        width: 36%;
        margin-left: auto;
        margin-right: auto;
    }

    .opsunizerCtaB-buttons {
        order: 2;
        width: 33%;
        margin-left: auto;
        margin-right: auto;
    }

    .opsunizerCtaB-icons {
        order: 3;
        width: 100%;
        max-width: 500px;
        margin: 20px auto 0;
    }
}

@include media-breakpoint-down(sm) {
    .opsunizerCtaB-texts { width: 50%; }

    .opsunizerCtaB-buttons { width: 44%; }

    .opsunizerCtaB-iconImage { margin-bottom: 10px; }
}

@include media-breakpoint-down(xs) {
    .opsunizerCtaB-title {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .opsunizerCtaB-subtitle { text-align: center; }

    .opsunizerCtaB-texts { width: 100%; }

    .opsunizerCtaB-buttons {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }

    .opsunizerCtaB-learnMore {
        display: inline-flex;
        align-items: center;
    }

    .opsunizerCtaB-learnMoreText { text-align: left; }

    .opsunizerCtaB-button {
        width: auto;
        margin-top: 10px;
    }

    .opsunizerCtaB-icons {
        flex-wrap: wrap;
        justify-content: center;
    }

    .opsunizerCtaB-icon { min-width: 33.33%; }
}
