

/* NEED BOOTSTRAP CSS */
.modal-backdrop { background-color: #fff; }
.modal-title { font-weight: 300; }
.modal-header { padding: 7px 15px; }
.modal-footer { padding: 7px 15px; }

.bootstrap-dialog.type-default .bootstrap-dialog-title { color:#333; }
.bootstrap-dialog.type-default .modal-header { background-color:#fff; }
.bootstrap-dialog.type-info .modal-header { background-color:#5bc0de; }
.bootstrap-dialog.type-primary .modal-header { background-color:#428bca; }
.bootstrap-dialog.type-success .modal-header { background-color:#5cb85c; }
.bootstrap-dialog.type-warning .modal-header { background-color:#f0ad4e; }
.bootstrap-dialog.type-danger .modal-header { background-color:#d9534f; }

.bootstrap-dialog.type-info .modal-header,
.bootstrap-dialog.type-primary .modal-header,
.bootstrap-dialog.type-success .modal-header,
.bootstrap-dialog.type-warning .modal-header,
.bootstrap-dialog.type-danger .modal-header {
	color: #fff;
}
.bootstrap-dialog.type-info .modal-header .close,
.bootstrap-dialog.type-primary .modal-header .close,
.bootstrap-dialog.type-success .modal-header .close,
.bootstrap-dialog.type-warning .modal-header .close,
.bootstrap-dialog.type-danger .modal-header .close {
	color: #fff;
}


/*.modal-footer { background-color: #fefefe; }*/
