
.distributors { margin-bottom: 140px; }

.distributors-title { @extend .title; }

.distributors-texts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.distributors-text { width: 39%; }

.distributors-textTitle {
    font-family: Oswald;
    font-size: 22px;
    line-height: 1.6;
    letter-spacing: 0.8px;
    color: $main-color;
    text-transform: uppercase;
}

.distributors-textParagraph {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.6;
    color: #404040;
}

.distributors-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.distributors-element {
    display: inline-block;
    width: calc(33.33% - 10px);
    margin: 5px 5px 5em;
    padding: 45px 0 33px 33px;
    background: #f4f4f4;
}

.distributors-elementImage {
    max-width: 100%;
    max-height: 64px;
    margin-bottom: 22px;
}

.distributors-elementText {
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.5;
    color: #9d9d9d;
    margin-bottom: 25px;

    > *:last-child { margin-bottom: 0; }
}

%distributors-elementLink {
    display: flex;
    align-items: center;

    span {
        font-size: 21px;
        color: gray;
        margin-right: 10px;
    }

    a {
        opacity: 0.8;
        font-family: Roboto;
        color: #404040;
    }
}

.distributors-elementEmail {
    @extend %distributors-elementLink;
    margin-bottom: 10px;

    a {
        font-size: 14px;
        transition: color 0.3s;
        word-break: break-all;

        &:hover {
            color: $main-color;
            text-decoration: underline;
        }
    }
}

.distributors-elementPhone {
    @extend %distributors-elementLink;

    a {
        font-size: 19px;
        font-weight: 900;
        cursor: default;

        &:hover {
            color: #404040;
            text-decoration: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .distributors-element { padding: 30px 15px 15px; }
}

@include media-breakpoint-down(sm) {
    .distributors-element { width: calc(50% - 10px); }
}

@include media-breakpoint-down(xs) {
    .distributors { margin-bottom: 50px; }

    .distributors-texts { display: block; }

    .distributors-text { width: 100%; }

    .distributors-list {
        margin-left: 0;
        margin-right: 0;
    }

    .distributors-element {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}
