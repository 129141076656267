
.applications { margin: 5% 0; }

.applications-header {
    background: #323232;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 38px;
    margin-bottom: 6px;
}

.applications-title {
    font-family: Roboto;
    font-size: 48px;
    font-weight: 100;
    line-height: 1;
    color: $main-color;
    margin-bottom: 0;
}

.applications-button {
    @extend .button-gold;
}

.applications-list {
    display: flex;
    margin-left: -3px;
    margin-right: -3px;
}

.applications-element {
    width: 25%;
    padding: 0 3px;
    position: relative;
}

.applications-elementTitle {
    border: solid 1px rgba(#348ce0, 0.6);
    background-color: rgba(#348ce0, 0.6);
    font-family: Oswald;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1.7px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    padding: 16px 0;
    position: absolute;
    top: 0;
    left: 3px;
    right: 3px;
    margin: 0;
}

.applications-elementImage { width: 100%; }

.applications-elementButtons {
    position: absolute;
    top: 80px;
    left: 4%;
    right: 4%;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: -22px;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 90px;
        background: #fff;
    }

    .applications-element:hover & { opacity: 1; }
}

.applications-elementButton {
    width: 50%;
    font-family: Oswald;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1.1px;
    text-align: center;
    color: #dfdfdf;
    background: rgba(#272727, 0.9);
    transition: color 0.3s, background 0.3s;
    padding: 7px 0;

    &:hover {
        color: $main-color;
        background: #252525;
        text-decoration: none;
    }
}


@include media-breakpoint-down(md) {
    .applications-title { font-size: 35px; }

    .applications-elementButtons {
        display: block;
        opacity: 1;

        &:after {
            content: '';
            display: none;
        }
    }

    .applications-elementButton {
        display: block;
        width: 100%;

        + .applications-elementButton { margin-top: 5px; }
    }
}

@include media-breakpoint-down(sm) {
    .applications-header { padding: 15px; }

    .applications-title { font-size: 27px; }

    .applications-list { flex-wrap: wrap; }

    .applications-element {
        width: 50%;

        &:nth-child(2) ~ .applications-element { margin-top: 15px; }
    }
}

@include media-breakpoint-down(xs) {
    .applications-header { align-items: flex-end; }

    .applications-title { font-size: 23px; }

    .applications-button {
        font-size: 12px;
        padding: 0.2em 0.5em;
        flex: 0 0 auto;
    }

    .applications-elementTitle {
        font-size: 17px;
        padding: 12px 0;
    }

    .applications-elementButtons { top: 50px; }

    .applications-elementButton {
        font-size: 14px;
        display: inline-block;
        width: 48%;
        padding: 5px 0;
    }
}
