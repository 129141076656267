
.projectHeader {
    background: #252525;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    max-width: 1366px;
    margin: auto;
    right: 0;
}

.projectHeader-placeholder { height: 150px; }

.projectHeader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
}

.projectHeader-mainInfo { width: 30%; }

.projectHeader-title {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
}

.projectHeader-date {
    font-family: Roboto;
    font-size: 13px;
    color: #9d9d9d;
    margin-bottom: 10px;
}

.projectHeader-back {
    @extend .button-tiny;
}

.projectHeader-features {
    display: flex;
    flex-wrap: wrap;
}

.projectHeader-feature {
    width: 33.33%;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 13px;

    &:nth-child(3) ~ * { margin-top: 30px; }
}

.projectHeader-featureLabel {
    width: 130px;
    color: #348ce0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        font-size: 22px;
        margin: 0 10px;
    }
}

.projectHeader-featureValue {
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 1px 0;

    .icon-triangle-right {
        font-size: 10px;
        margin-left: 10px;
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        .projectHeader-featureValueName { text-decoration: underline; }
    }
}

a.projectHeader-featureValue { padding-right: 15px; }

.projectHeader-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 28px;
    color: #9d9d9d;

    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

@include media-breakpoint-down(md) {
    .projectHeader-content {
        display: block;
        padding-top: 15px;
        height: auto;
        min-height: 170px;
    }

    .projectHeader-placeholder { height: 170px; }

    .projectHeader-mainInfo {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .projectHeader-date {
        margin-bottom: 0;
        margin-left: 15px;
    }

    .projectHeader-backContainer {
        margin-left: auto;
        margin-right: 35px;
    }

    .projectHeader-featureLabel { width: auto; }
}

@include media-breakpoint-down(sm) {
    .projectHeader-content { min-height: 195px; }

    .projectHeader-placeholder { height: 195px; }

    .projectHeader-feature { width: 50%; }

    .projectHeader-feature:nth-child(2) ~ * { margin-top: 15px; }
}

@include media-breakpoint-down(xs) {
    .projectHeader { position: relative; }

    .projectHeader-content { min-height: 330px; }

    .projectHeader-placeholder { display: none; }

    .projectHeader-mainInfo { flex-wrap: wrap; }

    .projectHeader-backContainer {
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
    }

    .projectHeader-feature {
        width: 100%;
        max-width: 348px;
        margin-left: auto;
        margin-right: auto;

        & + .projectHeader-feature { margin-top: 10px; }
    }

    .projectHeader-featureLabel {
        width: 120px;
        flex: 0 0 auto;
        justify-content: space-between;
    }
}
