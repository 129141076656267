    .wazzup,
    .cbp-wazzup .cbp-wrapper {
        position: initial;
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
        text-align: left;

        .cbp-filter-item { width: 100%; }
        .cbp-filter-item:after { display: none !important; }

        .cbp-item {
            position: initial;
            width: auto !important;
            padding: 15px;
        }

        .cbp-item-off { display: none; }

        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }

    .wazzup_element,
    .wazzup_feat_element,
    .wazzup_page { display: flex; }

    .wazzup_element{ padding: 15px; }

    .wazzup_element,
    .wazzup_element *,
    .wazzup_feat_element,
    .wazzup_feat_element *,
    .wazzup_page,
    .wazzup_page * { text-decoration: none !important; }

    .blog a { color: #0056b3; }

        .wazzup_element {
            flex-direction: column;
            /* DISABLED
            flex: 33.333%;
            max-width: 33.333%;
            */
        }
        /* DISABLED
            @media screen and (max-width: 991px) {
                .wazzup_element { flex: 50%; max-width: 50%; }
            }

            @media screen and (max-width: 767px) {
                .wazzup_element { flex: 100%; max-width: 100%; }
            }
        */
        .wazzup_feat_element,
        .wazzup_page {
            flex-direction: row;
            flex: 100%;
            margin-bottom: 15px;
        }
            @media screen and (max-width: 767px) {
                .wazzup_feat_element,
                .wazzup_page { flex-direction: column; }
            }

        .wazzup_content {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px 0 30px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            letter-spacing: .8px;
            color: #404040;
            border-bottom: 1px solid #ccc;
        }

        .wazzup_feat_element .wazzup_content {
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
        }

            .wazzup_page .wazzup_text,
            .wazzup_feat_element .wazzup_text { max-width: 650px; }

                .date { margin: 30px 0; }

                .cbp .wazzup_summary {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

            .wazzup_title,
            .wazzup_page .title {
                font-family: Oswald;
                line-height: 1.1;
            }

                .wazzup_page .wazzup_title,
                .wazzup_feat_element .wazzup_title { font-size: 48px; }

                .wazzup_page .title { font-size: 30px; }

                .wazzup_element .wazzup_title { font-size: 23px; }

                a:hover .wazzup_element .title { color: #d4ac55; }

                .wazzup_page .underlined_title {
                    padding: 5px 0;
                    font-size: 23px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

        .wazzup_detail {
            width: 100%;
            margin-top: 30px;
            font-size: 16px;
        }

    .wazzup_element img,
    .wazzup_page img,
    .wazzup_feat_element img {
        object-fit: cover;
        width: 100%;
        border-radius: 5px;
    }

        .wazzup_element img {
            height: 250px;
        }
        .wazzup_feat_element img {
            max-width: 50%;
            height: 500px;
        }

        .wazzup_page img {
            max-width: 50%;
            height: 350px;
        }

            @media screen and (max-width: 767px) {

                .wazzup_page img { order: -1; }

                .wazzup_page img,
                .wazzup_feat_element img {
                    max-width: 100%;
                    height: 250px;
                }
            }

    .wazzup_btn {
        align-self: flex-end;
        margin-top: 30px;
        color: #000 !important;
        text-transform: uppercase;
        border-color: #d4ac55 !important;
        background: #d4ac55 !important;

        &:hover { color: #fff !important; }
    }
        .wazzup_feat_element .wazzup_btn { margin-top: 0; }

        .wazzup_page .wazzup_btn {
            position: sticky;
            bottom: 5px;
            left: 100%;
            box-shadow: -10px -10px 20px #fff,
                        -10px 10px 20px #fff !important;
        }


.caption-tag {
    display: inline-block;
    margin: 15px 0 10px;
    font-size: 13px;
    line-height: 18px;
    padding: 3px 8px;
    color: #767676;
    border-radius: 2px;
    border: solid 1px #a3a3a3;
}

.blog { padding-top: 30px; }

.blog-container {
    max-width: 900px;
    margin: 0 auto;
}

.blog-title {
    margin: 35px 0 25px;
    font-family: "Roboto Condensed", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 30px !important;
    line-height: 1.5 !important;
    color: #252525 !important;
}

.blog-subTitle {
    margin-top: 25px;
    font-family: "Roboto Condensed", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 20px !important;
    line-height: 1 !important;
    color: #252525 !important;
}

.blog-subSubTitle {
    text-indent: 50px;
    font-family: "Roboto", sans-serif !important;
    font-style: italic !important;
    font-size: 16px !important;
}

.blog p {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif !important;
    font-size: 1.2em !important;
    line-height: 1.75 !important;
    text-align: justify;
}
