
body.hover-megamenu { overflow-x: hidden; }

.megamenu {
    @extend .container;

    width: auto;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 57px;
    left: -1000px;
    right: -1000px;
    display: flex;
    justify-content: center !important;

    .navbar-mainMenuItem-hover &,
    .navbar-mainMenuItem-clicked & { height: auto; }

    .last & {
        right: 0;
        left: auto;
        white-space: nowrap;
    }

    @include media-breakpoint-down(sm) {
        .navbar-mainMenuItem-hover &,
        .navbar-mainMenuItem-clicked & {
            position: static;
            display: block !important;
            height: auto;
        }
    }
}

.megamenu-content {
    border-radius: 6px;
    border: solid 2px $main-color;
    background-color: rgba(26, 26, 26, 0.95);
    margin-top: 18px;
    width: auto;
    display: block;
    vertical-align: top;

    @include media-breakpoint-down(sm) {
        margin: 0;
        border: 0;
        background: transparent;
    }
}

.megamenu-body {
    padding: 45px 64px 40px 60px;

    .megamenu-spacer {
        height: 33px;
        display: inline-block;
        vertical-align: top;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    > ul {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        > li > a {
            font-family: Oswald;
            font-size: 22px;
            font-weight: 300;
            line-height: 0.9;
            letter-spacing: 0.8px;
            color: #fff;
            text-transform: uppercase;
            display: block;
            padding-bottom: 13px;
        }

        ul a {
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.25;
            color: #d4ac55;
            display: block;
            padding: 6px 0;
            transition: color 0.3s;

            &:hover {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0 0 0 35px;

        > ul {
            flex-direction: column;
            gap: 0;

            > li > a {
                text-transform: none;
                font-size: 16px;
                padding-top: 10px;
                padding-bottom: 3px;
            }
        }

        ul ul { padding-left: 20px; }
    }
}

.megamenu-seeAll {
    font-family: Oswald;
    font-size: 14px;
    padding: 5px 0;
    letter-spacing: .4px;
    color: #fff;
    margin-bottom: 15px;
    display: inline-block;

    &:hover { color: #fff; }
}

.megamenu-footer {
    padding: 20px 60px;
    border-top: 1px solid #575757;
    display: flex;
    align-items: center;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        flex: 1 1 auto;
        margin-left: 5%;
        gap: 30px;
    }

    a {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 300;
        line-height: 1.2;
        color: #9d9d9d;
        display: block;
        padding: 7px 0;
    }
}

.megamenu-seeAlso {
    font-family: Oswald;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #fff;
    text-transform: uppercase;

    span { margin-left: 5px; }
}

@include media-breakpoint-down(lg) {
    .megamenu {
        position: fixed;
        top: 135px;
    }
}

@include media-breakpoint-down(md) {
    .megamenu-body,
    .megamenu-footer {
        padding-left: 35px;
        padding-right: 15px;
    }

    .megamenu-footer ul { margin-left: 15px; }
}

@include media-breakpoint-down(sm) {
    .megamenu { display: none; }

    .navbar-mainMenuItem .megamenu .megamenu-footer { display: none; }
}
