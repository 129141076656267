
.warranty {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.warranty-texts {
    font-family: Oswald;
    text-transform: uppercase;
}

.warranty-subtitle {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1px;
    color: #404040;
    margin: 0;
}

.warranty-subtitleBold {
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #404040;
    margin-bottom: 5px;
}

.warranty-subtitleDate {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 2px;
    color: #969696;
}

.warranty-image {
    margin-top: -100px;
    width: 180px;
}

@include media-breakpoint-down(xs) {
    .warranty-subtitle { font-size: 16px; }

    .warranty-subtitleBold { font-size: 16px; }

    .warranty-image {
        margin-top: -30px;
        width: 90px;
    }
}
