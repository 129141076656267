
.contactForm {
    @extend .darkForm;
    margin-top: 85px;
}

.contactForm-header {
    display: flex;
    margin-bottom: 33px;
}

.contactForm-title {
    @extend .darkForm-title;
    width: 50%;
    padding-right: 6px;
}

.contactForm-text {
    width: 50%;
    padding-left: 6px;
    line-height: 1.5;
    color: $main-color;

    > *:last-child { margin-bottom: 0; }
}

@include media-breakpoint-down(sm) {
    .contactForm-header { display: block; }

    .contactForm-title {
        width: 100%;
        padding-right: 0;
    }

    .contactForm-text {
        width: 100%;
        padding-left: 0;
    }
}

@include media-breakpoint-down(xs) {
    .contactForm { margin-top: 50px; }
}
