
body.search-popup-opened { overflow: hidden; }

.searchPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: rgba(37, 37, 37, 0.9);
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10%;
    z-index: 102;
    overflow: hidden;

    body.search-popup-opened & {
        opacity: 1;
        width: 100%;
        height: 100vh;
    }
}

.searchPopup-content { position: relative; }

.searchPopup-title {
    font-family: Roboto;
    font-size: 65px;
    font-weight: 100;
    color: $main-color;
    line-height: 1.4;
}

.searchPopup-form { position: relative; }

.searchPopup-input {
    width: 440px;
    height: 65px;
    padding-left: 28px;
    padding-right: 55px;
    font-size: 21px;
    font-weight: 300;
}

.searchPopup-button {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    padding: 0;
    background: none;
    height: 65px;
    font-size: 29px;
    color: $main-color;
    padding: 0 17px;
}

.searchPopup-close {
    position: absolute;
    bottom: 82px;
    right: 0;
    width: 40px;
    height: 40px;

    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 20px;
        left: 0;
        width: 40px;
        height: 1px;
        background: #fff;
    }

    &:before { transform: rotateZ(45deg); }

    &:after { transform: rotateZ(-45deg); }
}

@include media-breakpoint-down(xs) {
    .searchPopup-title { font-size: 50px; }

    .searchPopup-input { width: 100%; }
}
