
.socialMedia {
    position: absolute;
    top: 30px;
    right: 15px;
    display: flex;
}

.socialMedia-static {
    position: static;
    width: 150px;
    margin-left: auto;
    margin-top: 25px;

    + .title { margin-top: 0; }
}

.socialMedia-element {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:hover { text-decoration: none; }
}
