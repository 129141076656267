
.specifications {
    max-width: 1366px;
    margin: 0 auto;
    background: #323232;
}

.specifications-title {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.8;
    letter-spacing: 1px;
    color: $main-color;
    text-transform: uppercase;
    display: inline-block;
    padding: 7px 30px;
    background: #323232;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0;
    position: absolute;
    bottom: 100%;
    left: 15px;
}

.specifications-content {
    padding: 55px 0 50px;
    column-count: 3;
}

.specifications-element {
    padding: 0 25px 20px;
    border-left: 1px solid #696969;
    break-inside: avoid;
}

.specifications-elementTitle {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: $main-color;
    text-transform: uppercase;
}

.specifications-elementText {
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 0;
}

@include media-breakpoint-down(lg) {
    .specifications-element {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@include media-breakpoint-down(md) {
    .specifications-content { padding: 40px 0; }
}

@include media-breakpoint-down(sm) {
    .specifications-content { column-count: 2; }
}

@include media-breakpoint-down(xs) {
    .specifications-title {
        padding-left: 15px;
        padding-right: 15px;
    }

    .specifications-content { column-count: 1; }

    .specifications-element {
        border-left: 0;
        padding-left: 0;
        padding-right: 0;
    }
}
