
.job { padding-bottom: 50px; }

.job-form {
    @extend .container;
    position: fixed;
    z-index: 1;

    .formCard {
        position: absolute;
        top: 85px;
        right: 0;
        max-height: calc(100vh - 85px);
        overflow: auto;
    }
}

.job-header {
    background: url($common_dir + "/images/background-header.png") center 75% #252525;
    background-size: 100%;
}

.job-headerContent {
    min-height: 340px;
    padding: 23px 0;

    .socialMedia { position: static; }

    .socialMedia-element { color: #fff; }
}

.job-headerContentTopRow {
    display: flex;
    align-items: center;
}

.job-back {
    @extend .button-tiny;
}

.job-logo {
    margin-left: auto;
    margin-right: 107px;

    img { width: 89px; }
}

.job-name {
    @extend .title;
    width: calc(100% - (350px + 15px));
    font-size: 47px;
    color: #fff;
    margin-top: 65px;
    margin-bottom: 25px;
}

.job-details {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - (350px + 15px));
}

.job-detail {
    display: flex;
    align-items: center;
    width: 50%;
    font-family: Roboto;
    font-size: 13px;
    line-height: 2.5;
}

.job-detailName {
    text-align: right;
    color: #348ce0;
    flex: 0 0 auto;

    span {
        margin: 0 5px;
        font-size: 22px;
        vertical-align: middle;
    }

    .job-detail:nth-child(odd) & { width: 90px; }
    .job-detail:nth-child(even) & { width: 150px; }
}

.job-detailValue {
    opacity: 0.8;
    font-weight: 700;
    color: #fff;
    line-height: 1;
}

.job-body {
    @extend .defaultContent;
    width: calc(100% - (350px + 15px));
}

@include media-breakpoint-down(md) {
    .job {
        display: flex;
        flex-direction: column;
    }

    .job-header { order: 1; }

    .job-headerContent { min-height: 0; }

    .job-name,
    .job-details { width: 100%; }

    .job-name {
        font-size: 40px;
        margin-top: 40px;
    }

    .jobBody-container { order: 2; }

    .job-body { width: 100%; }

    .job-formContainer { order: 3; }

    .job-form {
        position: static;
        margin-top: 50px;

        .formCard { position: static; }
    }
}

@include media-breakpoint-down(sm) {
    .job-name { font-size: 35px; }
}

@include media-breakpoint-down(xs) {
    .job-headerContentTopRow {
        flex-wrap: wrap;

        .socialMedia {
            width: 100%;
            margin-top: 10px;
        }
    }

    .job-logo { margin-right: 0; }

    .job-detail { width: 100%; }

    .job-detailName { width: 150px !important; }

    .job-name {
        font-size: 30px;
        margin-top: 20px;
    }
}
