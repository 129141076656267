
.featuredProjects { margin: 5% 0; }

.featuredProjects-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.featuredProjects-title {
    font-family: Roboto;
    font-size: 48px;
    font-weight: 100;
    line-height: 0.8;
    color: #373737;
}

.featuredProjects-button {
    @extend .button-grey;
}

.featuredProjects-list { display: flex; }

@include media-breakpoint-down(xs) {
    .featuredProjects-title {
        font-size: 23px;
        font-weight: 300;
        margin-bottom: 0;
    }

    .featuredProjects-button {
        font-size: 12px;
        padding: 0.2em 0.5em;
    }
}
