
.header {
    position: sticky;
    top: -78px;
    width: 100%;
    /* max-width: 1366px; */
    margin: 0 auto;
    height: $header-height;
    background: #252525;
    z-index: 100;

    &:before {
        content: ' ';
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        background: rgba(37, 37, 37, 0.9);
        z-index: 99;
        transition: opacity 0.3s;
    }
}

.shrinkableHeader {
    /* max-width: 1366px; */
    width: 100%;
    height: $header-height;
    margin: 0 auto;
    z-index: 100;
    background: url($common_dir + "/images/background-header.png") center 75% #252525 no-repeat;
    background-size: 100%;
}

.shrinkableHeader-small,
.shrinkableHeader-pinned {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: top 0.3s;
}

.navbar { padding: 0; }

.navbar-brand {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 74px;
    left: 0;
    width: 214px;
    transition: width 0.3s, top 0.3s;

    img { width: 100%; }
}

.navbar-slogan {
    color: #cfa241 !important;
    position: absolute;
    top: 126px;
    left: 66px;
    font-size: 11px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Oswald' !important;
    transition: opacity 0.3s;
}

.navbar-toggler {
    border: 0;
    padding-right: 0;
}

.navbar-collapse {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
}

.navbar-topMenu {
    order: 1;
    display: flex;
}

.navbar-language {
    font-size: 11px;
    color: #848484;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 1.4em 1.2em 0.3em;
    display: inline-block;
    transition: color 0.3s;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.top_link a,
.headerRequestQuote,
.headerBlog,
.headerContactUs {
    font-size: 15px;
    line-height: 1.2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0 !important;
    padding: 0.8em 0.8em 0.4em;
    letter-spacing: 0.3px;
    margin-left: 0.5em;
    margin-bottom: 42px;
    transition: margin-bottom 0.3s;

    &:hover { color: #fff; }
}

.top_link a,
.headerRequestQuote {
    @extend .button-mediumGrey;

    order: 2;
    background: #3f3f3f;
    color: $main-color;
    border: 0;
}

.top_link .last,
.headerBlog {
    @extend .button-mediumGold;

    order: 3;
    color: #222;
    padding: .8em .8em .4em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: $main-color;
}

.headerContactUs {
    @extend .button-mediumGold;

    order: 3;
    background: $main-color;
    color: #222;
}

.navbar-mainMenu {
    order: 4;
    width: calc(100% - 40px);
    justify-content: flex-end;
}

.navbar-mainMenuItem {
    position: relative;
    z-index: 100;
    transition: opacity 0.3s;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 53px;
        left: 50%;
        width: 2px;
        margin-left: -1px;
        height: 22px;
        background: $main-color;
        display: none;
    }
}

.navbar-mainMenuElement {
    font-family: Oswald;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    padding: 20px 17px;

    &:before {
        background-color: $main-color;
        top: 10px;
        content: "";
        height: 2px;
        left: 17px;
        right: 17px;
        position: absolute;
        transform: scaleX(0) rotate(0);
        transform-origin: bottom right;
        transition: transform .25s ease-out;
    }

    &.active,
    &:hover {
        color: #fff;
        text-decoration: none;

        &:before {
            transform: scaleX(1) rotate(0);
            transform-origin: bottom left;
        }
    }

    &.hasMegaMenu {
        cursor: default;
        padding-right: 29px;

        &:before { right: 29px; }

        @include media-breakpoint-down(sm) {
            pointer-events: none;
        }
    }
}

.headerSearch {
    order: 5;
    color: $main-color;
    font-size: 24px;
    width: 40px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    transition: color 0.3s;
    align-self: center;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

@include media-breakpoint-down(lg) {
    .navbar-mainMenuElement {
        padding-left: 10px;
        padding-right: 10px;

        &:before {
            left: 10px;
            right: 10px;
        }

        &.hasMegaMenu {
            padding-right: 22px;

            &:before { right: 22px; }
        }
    }
}

@include media-breakpoint-up(md) {
    body.hover-megamenu {
        .header:before {
            opacity: 1;
            width: 100%;
            height: 100%;
        }

        .navbar-mainMenuItem:not(.navbar-mainMenuItem-hover) { opacity: 0.3; }
    }

    .navbar-mainMenuItem.navbar-mainMenuItem-hover:before { display: block; }

    .shrinkableHeader-small {
        top: -78px;

        .navbar-brand {
            width: 136px;
            top: 107px;
        }

        .navbar-slogan { opacity: 0; }

        .top_link a,
        .headerRequestQuote,
        .headerContactUs,
        .headerBlog { margin-bottom: 56px; }
    }
}

@include media-breakpoint-only(md) {
    .shrinkableHeader-small .navbar-brand { top: 100px; }
}

@include media-breakpoint-down(md) {
    .navbar-brand {
        width: 136px;
        top: 86px;
    }

    .navbar-slogan { display: none; }

    .navbar-mainMenuElement { font-size: 13px; }

    .headerSearch {
        order: 0;
        align-self: flex-start;
        margin-top: 0.3em;
    }
}

@include media-breakpoint-down(sm) {
    .header {
        top: 0;
        height: 60px;
    }

    .shrinkableHeader { height: auto; }

    .navbar-brand {
        position: static;
        margin: 0;
        width: 150px;

        img {
            padding: 8px 28px 8px 0;
            margin-top: 8px;
        }
    }

    .navbar-collapse {
        max-height: calc(100vh - 60px);
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .navbar-mainMenu { width: 100%; }

    .navbar-mainMenuItem:hover:before {
        content: '';
        display: none;
    }

    .navbar-mainMenuElement {
        padding: 9px 20px;
        text-align: left;

        &.active,
        &:hover {
            color: $main-color;

            &:before { transform: scaleX(0) rotate(0); }
        }
    }

    .top_link a,
    .headerContactUs,
    .headerRequestQuote,
    .headerBlog {
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin-top: 1.5em;
        margin-bottom: 1em;
    }

    .top_link {
        display: inline-block;

        a {
            border-radius: 5px !important;
            padding: 8px 13px !important;
        }
    }

    .headerSearch {
        order: 9;
        margin: 12px;
        display: block;
    }

    .navbar-topMenu {
        order: 10;
        width: 100%;;
        flex-wrap: wrap;
        padding: 1em;
        display: block;
        margin-bottom: 1em;
    }

    .lang_menu { flex: 1 100%; }

    .navbar-language {
        font-family: Oswald;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 1px;
        color: $main-color;
        text-transform: uppercase;
        padding: 6px 10px;
        border: 1px solid $main-color;
        border-radius: 4px;
        display: inline-block;
        margin-left: .5em;
        margin-bottom: 5px;
        transition: color 0.3s, border-color 0.3s;

        &:hover {
            text-decoration: none;
            color: #fff;
            border-color: #fff;
        }
    }
}
