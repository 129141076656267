
.featuredProductCard {
    margin-bottom: 5.5em;
    background-size: 67%;
    background-color: #252525;
    background-repeat: no-repeat;
    background-position: right center;
}

.featuredProductCard-background-trees_1   { background-image: url($common_dir + "/images/productHeader-background-trees_1.jpg"); }
.featuredProductCard-background-trees_2   { background-image: url($common_dir + "/images/productHeader-background-trees_2.jpg"); }
.featuredProductCard-background-sky       { background-image: url($common_dir + "/images/productHeader-background-sky.jpg"); }
.featuredProductCard-background-mountains { background-image: url($common_dir + "/images/productHeader-background-mountains.jpg"); }
.featuredProductCard-background-building  { background-image: url($common_dir + "/images/productHeader-background-building.jpg"); }
.featuredProductCard-background-trees_3   { background-image: url($common_dir + "/images/productHeader-background-trees_3.jpg"); }
.featuredProductCard-background-city      { background-image: url($common_dir + "/images/productHeader-background-city.jpg"); }

.featuredProductCard-link {
    display: flex;
    align-items: center;
    position: relative;

    &:hover { text-decoration: none; }

    &:before {
        content: ' ';
        display: block;
        background: url($common_dir + "/images/featured_productCard-diagonal.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 43%;
        height: 100%;
        z-index: 1;
    }
}

.featuredProductCard-texts {
    width: 20%;
    margin-left: 9%;
    position: relative;
    z-index: 2;
}

.featuredProductCard-title {
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;

    sup {
        font-size: 0.5em;
        font-weight: 400;
        top: -0.8em;
    }
}

.featuredProductCard-text {
    opacity: 0.8;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.2;
    color: #c4c4c4;
}

.featuredProductCard-button {
    @extend .button-gold;
}

.featuredProductCard-image {
    width: 67%;
    margin-left: auto;
}

@include media-breakpoint-down(lg) {
    .featuredProductCard-texts {
        margin-left: 5%;
        width: 25%;
    }
}

@include media-breakpoint-down(md) {
    .featuredProductCard-link {
        display: block;

        &:before {
            content: '';
            display: none;
        }
    }

    .featuredProductCard-texts {
        width: 100%;
        margin-left: 0;
        padding: 20px;
    }

    .featuredProductCard-image {
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .featuredProductCard-title { font-size: 30px; }
}

@include media-breakpoint-down(xs) {
    .featuredProductCard-title { font-size: 25px; }
}
