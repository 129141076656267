
.form-check-inline { // Overwrite de bootstrap
    margin-left: 0 !important;
    margin-right: 0.75rem;
}

.publicForm-otherValue {
    display: inline-block;
    vertical-align: middle;
}

.publicForm-otherText {
    opacity: 0;
}

.publicForm-other-visible .publicForm-otherText {
    opacity: 1;
}

.publicForm-deleteFile {
    display: inline-block;
    vertical-align: middle;
    color: red;
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;

    &:hover {
        color: red;
        text-decoration: none;
    }
}

.publicForm-success {
    display: none;

    > *:last-child {
        margin-bottom: 0;
    }
}

.imcSelect {
    position: relative;

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }
}

.imcSelect-drop {
    font-size: 0.7rem;
    line-height: 2;
    color: #aaa;
    float: right;
}

.fakePlaceholder {
    position: absolute;
    top: 10px;
    left: 18px;
    color: #666;
    font-weight: 300;
    font-size: 0.8rem;
    transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;

    &.fakePlaceholder-minify {
        top: 1px;
        left: 11px;
        font-size: 9px;
        color: $main-color;
    }

    .form-group.has-error & { color: #da1830; }
}

.form-control {
    transition: border-color 0.3s;

    &:focus {
        border-color: $main-color;
        box-shadow: none;
    }

    .form-group.has-error & {
        border-color: #da1830;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.publicForm .alert-danger {
    border: 0;
    background: #da1830;
    color: #fff;
    padding: .375rem .75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0;
}

.publicForm-success {
    background: none;
    padding: 0;
    border: 0;
    color: #fff;
    font-size: 20px;
}
