
%button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: none;
    border-radius: 4px;
    transition: background 0.3s, color 0.3s, border 0.3s;
    font-family: Oswald;
    padding: 0.5em 1em;

    &:hover { text-decoration: none; }

    span + span { margin-left: 0.5em; }
}

.button-blue {
    @extend %button;
    border: 1px solid #348ce0;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #348ce0;
    text-transform: uppercase;

    &:hover {
        border-color: #fff;
        color: #fff;
    }
}

.button-mediumGrey {
    @extend %button;
    border: 1px solid #fff;
    font-size: 15px;
    letter-spacing: 0.9px;
    color: #fcfcfc;
    text-transform: uppercase;
}

.button-mediumWhite {
    @extend %button;
    border: 1px solid #fff;
    font-size: 15px;
    letter-spacing: 0.9px;
    color: #fff;
    text-transform: uppercase;

    &:hover {
        color: $main-color;
        border-color: $main-color;
    }
}

.button-mediumGold {
    @extend %button;
    border: 1px solid $main-color;
    font-size: 15px;
    letter-spacing: 0.9px;
    color: $main-color;
    text-transform: uppercase;

    &:hover {
        color: #fff;
        border-color: #fff;
    }
}

.button-gold {
    @extend %button;
    border: 1px solid $main-color;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: $main-color;

    &:hover {
        color: #fff;
        border-color: #fff;
    }
}

.button-grey {
    @extend %button;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 14px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
        color: $main-color;
        border-color: $main-color;
    }
}

.button-greyUppercase {
    @extend %button;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 14px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    &:hover {
        color: #000;
        border-color: #000;
    }
}

.button-background {
    @extend %button;
    background: $main-color;
    font-size: 15px;
    letter-spacing: 0.9px;
    color: #fff;
    text-transform: uppercase;
    height: 40px;
}

.button-big {
    @extend %button;
    border: 1px solid $main-color;
    font-size: 19px;
    letter-spacing: 1.1px;
    color: $main-color;
    text-transform: uppercase;

    &:hover {
        color: #fff;
        border-color: #fff;
    }
}

.button-tiny {
    @extend %button;
    border: 1px solid $main-color;
    font-size: 11px;
    letter-spacing: 0.4px;
    color: $main-color;

    &:hover {
        color: #fff;
        border-color: #fff;
    }
}
