
.pdf {
    background-color: #f7f7f7;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 90px;
    width: 100%;
    max-width: 530px;

    &:hover { text-decoration: none; }
}

.pdf-infos {
    padding-top: 50px;
    padding-left: 45px;
    text-align: left;
}

.pdf-date {
    font-family: Montserrat;
    font-size: 10px;
    letter-spacing: 1.8px;
    color: #8c8c8c;
    text-transform: uppercase;
}

.pdf-name {
    font-family: Oswald;
    font-size: 24px;
    letter-spacing: -0.2px;
    color: #393939;
}

.pdf-button {
    width: 170px;
    background: url($common_dir + "/images/background-sheets.jpg") no-repeat center center #f7f7f7;
    background-size: cover;
    mix-blend-mode: multiply;
    text-align: center;
    padding: 57px 0 13px;
}

.pdf-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: #000;
}

.pdf-arrow { margin: 30px auto 0; }

@include media-breakpoint-down(xs) {
    .pdf-infos { padding-left: 15px; }
}
