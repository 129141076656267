
.productHeader {
    padding-bottom: 58px;
    position: relative;
    background-size: cover;
    background-color: #252525;
    background-repeat: no-repeat;
    background-position: center top;

    .container { z-index: 2; }

    .largeContainer {
        background: transparent;

        @include media-breakpoint-up(lg) {
            position: relative;
        }
    }
}

.productHeader-background-trees_1   { background-image: url($common_dir + "/images/productHeader-background-trees_1.jpg"); }
.productHeader-background-trees_2   { background-image: url($common_dir + "/images/productHeader-background-trees_2.jpg"); }
.productHeader-background-sky       { background-image: url($common_dir + "/images/productHeader-background-sky.jpg"); }
.productHeader-background-mountains { background-image: url($common_dir + "/images/productHeader-background-mountains.jpg"); }
.productHeader-background-building  { background-image: url($common_dir + "/images/productHeader-background-building.jpg"); }
.productHeader-background-trees_3   { background-image: url($common_dir + "/images/productHeader-background-trees_3.jpg"); }
.productHeader-background-city      { background-image: url($common_dir + "/images/productHeader-background-city.jpg"); }

.productHeader-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        top: auto;
        bottom: -58px;
    }
}

.productHeader-top {
    display: flex;
    align-items: flex-start;
}

.productHeader-logo {
    margin-top: 31px;
    opacity: 0.5;

    img { width: 114px; }
}

.productHeader-opsunizer {
    padding: 38px 5px 10px;
    margin-left: auto;
    margin-right: 60px;
    background: #252525;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    width: 147px;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #252525;
    }

    &:hover { text-decoration: none; }
}

.productHeader-opsunizerImage {
    width: 130px;
    margin: 0 auto 5px;
}

.productHeader-opsunizerText {
    font-family: Oswald;
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 0.6px;
    text-align: center;
    color: $main-color;
    text-transform: uppercase;
}

.productHeader-icons {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-left: auto;

    .productHeader-opsunizer + & { margin-left: 0; }
}

.productHeader-icon {
    width: 65px;

    img { width: 100%; }

    + .productHeader-icon { margin-left: 28px; }
}

.productHeader-texts {
    min-height: 413px;
    margin-top: 54px;
}

.productHeader-breadcrumb {
    font-family: Roboto;
    font-size: 12px;
    color: #9d9d9d;
}

.productHeader-breadcrumbLink {
    color: #9d9d9d;
    display: inline-block;
    padding: 6px 0;

    &:hover { color: #9d9d9d; }
}

.productHeader-title {
    font-family: Montserrat;
    font-size: 49px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    max-width: 350px;

    sup {
        font-size: 0.5em;
        font-weight: 400;
        line-height: 1.7;
        top: -0.8em;
    }
}

.productHeader-description {
    opacity: 0.8;
    font-family: Roboto;
    font-size: 17px;
    line-height: 1.9;
    letter-spacing: 0.5px;
    color: #348ce0;
    text-transform: uppercase;
    margin-bottom: 0;
    max-width: 315px;
}

.productHeader-certifications {
    display: flex;
}

.productHeader-certification {
    margin-top: 20px;
    opacity: 0.45;

    + .productHeader-certification { margin-left: 20px; }
}

.productHeader-thumbs {
    display: flex;
    justify-content: flex-end;
}

.productHeader-thumb {
    display: block;
    border: 2px solid transparent;
    padding: 4px;
    transition: border 0.3s;
    max-width: calc(100% / 6);

    img {
        width: 100%;
        max-width: 77px;
        background: #2e2e2e;
    }

    &:hover { border-color: $main-color; }
}

@include media-breakpoint-down(lg) {
    .productHeader-image {
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .productHeader-texts {
        width: 362px;
        padding: 0 6px;
        position: relative;
        left: -6px;
        background: rgba(#252525, 0.9);
    }
}

@include media-breakpoint-down(md) {
    .productHeader-title { font-size: 45px; }
}

@include media-breakpoint-down(sm) {
    .productHeader-opsunizer { margin-right: auto; }

    .productHeader-title { font-size: 40px; }

    .productHeader-logo { display: none; }
}

@include media-breakpoint-down(xs) {
    .productHeader {
        padding-top: 65%;
        padding-bottom: 76px;
    }

    .productHeader-opsunizer {
        margin-right: 0;
        padding-top: 3px;
        padding-bottom: 3px;
        order: 2;
        background: none;
    }

    .productHeader-icons {
        order: 1;
        margin: 0;
    }

    .productHeader-icon { width: 55px; }

    .productHeader-texts {
        width: calc(100% + 12px);
        min-height: 0;
        margin-bottom: 30px;
    }

    .productHeader-breadcrumb {
        line-height: 1;
        margin-bottom: 10px;
    }

    .productHeader-breadcrumbLink { padding: 3px 0; }

    .productHeader-title,
    .productHeader-description { max-width: none; }

    .productHeader-title { font-size: 30px; }

    .productHeader-description { font-size: 12px; }

    .productHeader-thumbs {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .productHeader-thumb { max-width: calc(100% / 4); }
}
