
.documents { margin: 55px 0; }

.documents-title {
    font-family: Roboto;
    font-size: 27px;
    font-weight: 300;
    line-height: 1.3;
    color: #404040;
    display: inline-block;
    border: solid 1px #dcdcdc;
    margin-bottom: -1px;
    border-bottom-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 7px 30px;
}

.documents-elements {
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #dcdcdc;
    padding: 20px;
    border-radius: 8px;
    border-top-left-radius: 0;
}

.documents-element {
    text-align: center;
    width: calc(100% / 6 - 20px);
    margin: 0 10px;
    padding: 27px 0;

    &:hover { text-decoration: none; }
}

.documents-element-sheets {
    background: url($common_dir + "/images/background-sheets.jpg") no-repeat center center;
    background-size: contain;
    order: 1;
}

.documents-element-technical {
    background: url($common_dir + "/images/background-technical.jpg") no-repeat center center;
    background-size: contain;
    order: 2;
}

.documents-elementName {
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    color: #404040;
    text-transform: uppercase;
}

.documents-elementImage,
.documents-elementHoverImage {
    margin: 10px auto 0;
    width: 54px;
    display: block;
}

.documents-element:hover .documents-elementImage,
.documents-element:not(:hover) .documents-elementHoverImage { display: none; }

@include media-breakpoint-down(lg) {
    .documents-element { width: calc(100% / 5  - 20px); }
}

@include media-breakpoint-down(md) {
    .documents-element { width: calc(100% / 4  - 20px); }
}

@include media-breakpoint-down(sm) {
    .documents-title { font-size: 25px; }

    .documents-element { width: calc(100% / 3  - 20px); }
}

@include media-breakpoint-down(xs) {
    .documents-title {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .documents-element {
        width: calc(100% / 2  - 20px);
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    .documents-elementName {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .documents-elementHoverImage, .documents-elementImage { margin-top: auto; }
}
