
.whatWeDo {
    text-align: center;
    margin: 5% 0;
}

.whatWeDo-title {
    font-family: Roboto;
    font-size: 48px;
    font-weight: 100;
    line-height: 1.1;
    color: #373737;
    margin-bottom: 50px;
}

.whatWeDo-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-left: -15px;
    margin-right: -15px;
}

.whatWeDo-element { padding: 0 15px; }

.whatWeDo-elementImage {
    width: 48px;
    margin: 0 auto 15px;
}

.whatWeDo-elementTitle {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #348ce0;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.whatWeDo-elementText {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.9;
    color: #383b41;
}

.whatWeDo-footer {
    font-family: Roboto;
    font-size: 48px;
    font-weight: 100;
    line-height: 1.1;
    color: #373737;
}

@include media-breakpoint-down(sm) {
    .whatWeDo-title { font-size: 40px; }

    .whatWeDo-list {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }

    .whatWeDo-element {
        padding-left: 0;
        padding-right: 0;
    }

    .whatWeDo-footer { font-size: 40px; }
}

@include media-breakpoint-down(xs) {
    .whatWeDo-title { font-size: 30px; }

    .whatWeDo-footer { font-size: 30px; }
}
