
.opsunizerPage {
    background-size: cover;
    background-position: top;
    background-color: #0f4580;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url($common_dir + "/images/about-opsunizer-background-2.jpg");
    padding-top: 130px;
    padding-bottom: 94px;

    #page_5115c2342f555414482fe5e09141ebd6 & { padding: 0; } /* Block on Homepage */
}

.opsunizerPage-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 130px;
}

.opsunizerPage-title {
    width: 100%;
    max-width: 495px;
    margin-bottom: 0;

    img { width: 100%; }
}

.opsunizerPage-subtitle {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.4px;
    color: $main-color;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: auto;
    max-width: 282px;
    margin-right: 30px;
}

.opsunizerPage-button {
    @extend .button-big;

    padding: 0.5em;
    min-width: 176px;
    white-space: nowrap;
}

.opsunizerPage-bigBoxes { margin-bottom: 68px; }

.opsunizerPage-bigBox {
    display: flex;
    align-items: center;

    &:nth-child(odd) { background-color: rgba(#348ce0, 0.2); }

    &:nth-child(even) { background-color: rgba(#063665, 0.6); }
}

.opsunizerPage-bigBoxText {
    width: 50%;
    padding: 20px 95px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .opsunizerPage-bigBox:nth-child(odd) & { text-align: right; }

    .opsunizerPage-bigBox:nth-child(even) & {
        order: 2;
        text-align: left;
    }
}

.opsunizerPage-bigBoxTitle {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1px;
    color: $main-color;
    margin-bottom: 1em;
}

.opsunizerPage-bigBoxParagraph {
    font-family: Roboto;
    font-size: 13px;
    line-height: 2;
    color: #fff;
    margin-bottom: 0;
}

.opsunizerPage-bigBoxImage {
    width: 50%;

    .opsunizerPage-bigBox:nth-child(even) & { order: 1; }
}

.opsunizerPage-smallBoxes { margin-bottom: 142px; }

.opsunizerPage-smallBox {
    display: flex;
    align-items: center;

    + .opsunizerPage-smallBox {
        margin-top: -86px;
        margin-left: calc(25% + 78px);
    }
}

.opsunizerPage-smallBoxImage {
    width: 295px;
    flex: 0 0 auto;
}

.opsunizerPage-smallBoxText {
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.9;
    color: #fff;
    margin-left: 78px;
    margin-bottom: 0;

    .opsunizerPage-smallBox:first-child & { margin-bottom: 86px; }
}

.opsunizerPage-details {
    padding: 80px 100px;
    background-color: rgba(#063665, 0.6);
}

.opsunizerPage-detailsTop {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
}

.opsunizerPage-detailsIcons {
    display: flex;
    justify-content: space-between;
    margin-right: 80px;
    flex: 1 1 auto;
}

.opsunizerPage-detailsIconImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;
    height: 65px;

    img {
        height: 45px;
        margin: auto;
        flex: 0 0 auto;
    }
}

.opsunizerPage-detailsIconImage-taller img { height: 65px; }

.opsunizerPage-detailsIconName {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.7;
    text-align: center;
    color: $main-color;
    text-transform: uppercase;
    max-width: 100px;
    margin: 0 auto;
}

.opsunizerPage-detailsButton {
    @extend .button-big;
}

.opsunizerPage-detailsText {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.9;
    letter-spacing: 0.9px;
    color: #50a9ff;
    text-transform: uppercase;
    margin-bottom: 0;
}

@include media-breakpoint-down(lg) {
    .opsunizerPage-header {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .opsunizerPage-title { order: 1; }

    .opsunizerPage-button { order: 2; }

    .opsunizerPage-subtitle {
        order: 3;
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0.5em;
    }

    .opsunizerPage-bigBoxText {
        padding-left: 60px;
        padding-right: 60px;
    }

    .opsunizerPage-smallBoxes { margin-bottom: 100px; }

    .opsunizerPage-smallBoxText { margin-left: 20px; }

    .opsunizerPage-details { padding: 50px; }
}

@include media-breakpoint-down(md) {
    .opsunizerPage { padding: 90px 0; }

    .opsunizerPage-header { margin-bottom: 70px; }

    .opsunizerPage-title { max-width: 430px; }

    .opsunizerPage-bigBoxText { padding: 0 15px; }

    .opsunizerPage-bigBoxParagraph { line-height: 1.6; }

    .opsunizerPage-smallBox:first-child .opsunizerPage-smallBoxText { margin-bottom: 50px; }

    .opsunizerPage-smallBox + .opsunizerPage-smallBox {
        margin-top: -50px;
        margin-left: 0;

        .opsunizerPage-smallBoxText {
            order: 1;
            text-align: right;
            margin-left: 0;
            margin-right: 20px;
        }

        .opsunizerPage-smallBoxImage { order: 2; }
    }

    .opsunizerPage-details { padding: 20px; }

    .opsunizerPage-detailsTop {
        display: block;
        margin-bottom: 30px;
    }

    .opsunizerPage-detailsIcons {
        margin-right: 0;
        margin-bottom: 2em;
    }

    .opsunizerPage-detailsIconImage { margin-bottom: 15px; }
}

@include media-breakpoint-down(sm) {
    .opsunizerPage { padding: 70px 0; }

    .opsunizerPage-header {
        display: block;
        margin-bottom: 50px;
    }

    .opsunizerPage-button { margin-top: 1em; }

    .opsunizerPage-bigBox {
        flex-direction: column;
        padding: 15px 0;
    }

    .opsunizerPage-bigBoxText {
        width: 100%;

        .opsunizerPage-bigBox:nth-child(odd) & { text-align: left; }

        .opsunizerPage-bigBox:nth-child(even) & { order: 1; }
    }

    .opsunizerPage-bigBoxTitle { margin-bottom: 0.5em; }

    .opsunizerPage-bigBoxImage {
        width: 100%;
        max-width: 300px;
        margin: 15px auto 0;

        .opsunizerPage-bigBox:nth-child(even) & { order: 2; }
    }

    .opsunizerPage-smallBox:first-child .opsunizerPage-smallBoxText { margin-bottom: 0; }

    .opsunizerPage-smallBox + .opsunizerPage-smallBox { margin-top: 1em; }

    .opsunizerPage-smallBoxImage { width: 250px; }
}

@include media-breakpoint-down(xs) {
    .opsunizerPage { padding: 50px 0; }

    .opsunizerPage-header { text-align: center; }

    .opsunizerPage-title { max-width: 300px; }

    .opsunizerPage-button { font-size: 16px; }

    .opsunizerPage-bigBox { padding: 24px; }

    .opsunizerPage-bigBoxText {
        padding-left: 6px;
        padding-right: 6px;
    }

    .opsunizerPage-smallBox {
        flex-direction: column;

        & + .opsunizerPage-smallBox { margin-top: 3em; }
    }

    .opsunizerPage-smallBoxText {
        margin-left: 0;
        margin-bottom: 1em;
        order: 1;
        text-align: center;
        padding: 24px;

        .opsunizerPage-smallBox + .opsunizerPage-smallBox & { text-align: left; }
    }

    .opsunizerPage-smallBoxImage {
        width: 80%;
        max-width: 300px;
        margin: 0 auto;
        order: 2;
    }

    .opsunizerPage-details { padding: 15px; }

    .opsunizerPage-detailsIcons {
        flex-wrap: wrap;
        justify-content: center;
    }

    .opsunizerPage-detailsText { font-size: 12px; }
}
