
.blueBackground {
    background: #0c457c;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blueBackground-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    mix-blend-mode: luminosity;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.blueBackground-gradient {
    mix-blend-mode: multiply;
    background: -moz-radial-gradient(center, ellipse cover,  rgba(62,144,188,0) 0%, rgba(62,144,188,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(62,144,188,0) 0%,rgba(62,144,188,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(62,144,188,0) 0%,rgba(62,144,188,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003e90bc', endColorstr='#3e90bc',GradientType=1 );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
