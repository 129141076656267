
.jobList-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.jobList,
.jobList-noJobs { width: calc(100% - (350px + 15px)); }

.jobList {
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
}

.jobList-noJobs {
    @extend .defaultContent;
}

.jobCard {
    background: #f4f4f4;
    width: calc(50% - 12px);
    margin: 6px;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.jobCard-publishedDate {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #8c8c8c;
    text-transform: uppercase;
}

.jobCard-title {
    font-family: Oswald;
    font-size: 33px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: -0.3px;
    color: #393939;
}

.jobCard-description {
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.5;
    color: #636363;
}

.jobCard-location,
.jobCard-hours {
    opacity: 0.8;
    font-family: Roboto;
    font-size: 13px;
    line-height: 2.5;
    color: #555;

    span {
        color: #b6b6b0;
        font-size: 22px;
        vertical-align: middle;
        margin-right: 5px;
    }
}

.jobCard-location { margin-top: auto; }

.jobCard-button {
    @extend .button-grey;
    margin-top: 25px;
}

@include media-breakpoint-down(md) {
    .jobList,
    .jobList-noJobs { width: calc(100% - (300px + 15px)); }

    .jobList-container {
        display: block;

        .formCard { margin: 50px auto 0; }
    }

    .jobList { width: 100%; }
}

@include media-breakpoint-down(sm) {
    .jobList-noJobs { width: 100%; }

    .jobList { width: calc(100% + 12px); }

    .jobCard { padding: 20px; }
}

@include media-breakpoint-down(xs) {
    .jobList,
    .jobList-noJobs { margin-top: -1em; }

    .jobList {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .jobCard {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
}
