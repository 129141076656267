
.testimonials {
    position: relative;
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.04);
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 90px;
}

.testimonials-title {
    position: absolute;
    top: 70px;
    left: 43%;
    z-index: 3;
    font-family: Roboto;
    font-size: 56px;
    font-weight: 100;
    line-height: 0.6;
    color: $main-color;
}

.testimonials-element {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    opacity: 0;
    transition: opacity 0.5s;

    &:first-child { position: static; }
}

.testimonials-element-active { opacity: 1; }

.testimonials-elementImage { width: 36%; }

.testimonials-elementTexts {
    margin-left: 7%;
    padding-top: 145px;
    padding-bottom: 45px;
    width: 53%;
}

.testimonials-elementText {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;
    color: #383b41;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 32%;
        height: 1px;
        background: #dbdbdb;
    }
}

.testimonials-elementPerson {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.9;
    color: #383b41;
}

.testimonials-elementJob {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.7;
    color: #383b41;
}

.testimonials-bullets {
    position: absolute;
    bottom: 15px;
    left: 43%;
    z-index: 3;
    display: flex;
}

.testimonials-bullet {
    width: 30px;
    height: 30px;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        border: solid 1px #fff;
        background: #d2d2d2;
        transition: background 0.3s;
    }
}

.testimonials-bullet-active:before { background: $main-color; }

@include media-breakpoint-down(md) {
    .testimonials-title {
        font-size: 50px;
        top: 30px;
    }

    .testimonials-elementTexts { padding-top: 90px; }
}

@include media-breakpoint-down(sm) {
    .testimonials-title {
        font-size: 40px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .testimonials-element {
        padding-left: 15px;
        padding-right: 15px;
    }

    .testimonials-elementImage { display: none; }

    .testimonials-elementTexts {
        width: 100%;
        margin-left: 0;
    }

    .testimonials-bullets {
        left: 50%;
        transform: translateX(-50%);
    }
}

@include media-breakpoint-down(xs) {
    .testimonials-title { font-size: 35px; }
}
