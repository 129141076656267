
.totalSupportPage {
    background: url($common_dir + "/images/support-background.jpg") no-repeat 0 0;
    background-size: 100%;
    background-color: #0b3f79;
    padding-bottom: 100px;
    text-align: center;

    .container {
        display: inline-block;
        text-align: left;
    }
}

.totalSupportPage-title {
    @extend .title;
    color: #fff;

    sup {
        font-size: 0.3em;
        text-transform: uppercase;
        top: -1.8em;
    }
}

.totalSupportPage-description {
    font-family: Roboto;
    font-size: 17px;
    line-height: 2;
    letter-spacing: 0.7px;
    color: #fff;
}

.totalSupportPage-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.totalSupportPage-feature {
    width: 42%;
    margin-top: 80px;
}

.totalSupportPage-featureName {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1px;
    color: #f5c142;
    text-transform: uppercase;
    padding-bottom: 27px;
    margin-bottom: 25px;
    position: relative;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 18%;
        height: 2px;
        background: #f5c142;
    }
}

.totalSupportPage-featureText {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.7;
    color: #fff;

    > *:last-child { margin-bottom: 0; }
}

@include media-breakpoint-down(md) {
    .totalSupportPage-feature { width: 45%; }
}

@include media-breakpoint-down(sm) {
    .totalSupportPage-description { font-size: 14px; }

    .totalSupportPage-feature {
        width: 100%;
        margin-top: 50px;
    }
}

@include media-breakpoint-down(xs) {
    .totalSupportPage { padding-bottom: 50px; }

    .totalSupportPage-featureName { font-size: 25px; }
}

