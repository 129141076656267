
.totalSupportCta {
    padding: 50px 70px;
    margin: 55px 0;
    position: relative;
}

.totalSupportCta-title {
    font-family: Oswald;
    font-size: 43px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #fff;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    sup {
        font-size: 11px;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        top: -2.4em;
        left: 0.4em;
    }
}

.totalSupportCta-text {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.7px;
    color: #fff;
    margin-bottom: 45px;
    position: relative;
    z-index: 2;
}

.totalSupportCta-list {
    display: flex;
    position: relative;
    z-index: 2;
}

.totalSupportCta-element {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.totalSupportCta-elementTitle {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #f5c142;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 40px;

    span { display: block; }

    &:after {
        content: ' ';
        display: block;
        width: 36%;
        height: 2px;
        background: $main-color;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.totalSupportCta-elementText {
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.5;
    color: #d2d2d2;
    width: 80%;
}

.totalSupportCta-elementButton {
    @extend .button-blue;
    margin-top: auto;
}

@include media-breakpoint-down(lg) {
    .totalSupportCta { padding: 30px; }
}

@include media-breakpoint-down(md) {
    .totalSupportCta-list { flex-wrap: wrap; }

    .totalSupportCta-element {
        width: 50%;

        &:nth-child(2) ~ .totalSupportCta-element { margin-top: 30px; }
    }

    .totalSupportCta-elementTitle { margin-bottom: 10px; }
}

@include media-breakpoint-down(sm) {
    .totalSupportCta-title { font-size: 36px; }
}

@include media-breakpoint-down(xs) {
    .totalSupportCta {
        padding: 15px;
        margin-bottom: 24px;
    }

    .totalSupportCta-title { font-size: 26px; }

    .totalSupportCta-element {
        width: 100%;

        &:first-child ~ .totalSupportCta-element { margin-top: 30px; }
    }

    .totalSupportCta-elementTitle {
        font-size: 18px;

        span { display: inline; }
    }

    .totalSupportCta-elementText {
        width: 100%;
        font-size: 13px;
    }
}
