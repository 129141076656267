
.banner {
  display: block;
  margin: 4rem 0;

  .cbp & { margin: 0 !important; }

  @include media-breakpoint-down(sm) {
    margin: 2rem 0;
  }
}
