
.grayBlock {
    margin-top: 100px;
    margin-bottom: 80px;

    + .grayBlock { margin-top: -76px; }
}

.grayBlock-content {
    background-color: #efefef;
    padding: 35px 40px;
}

.grayBlock-title {
    font-family: Oswald;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: #404040;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.grayBlock-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    color: #404040;

    > *:last-child { margin-bottom: 0; }
}

@include media-breakpoint-down(sm) {
    .grayBlock-content { padding: 15px; }

    .grayBlock-title { font-size: 20px; }
}

@include media-breakpoint-down(xs) {
    .grayBlock {
        margin-top: 50px;
        margin-bottom: 50px;

        + .grayBlock { margin-top: -46px; }
    }

    .grayBlock-text { font-size: 12px; }
}
