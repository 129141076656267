
.productCard { width: 50%; }

.productCard-link {
    display: flex;
    align-items: center;
    background: #f1f1f2;
    height: 320px;
}

.productCard-image {
    height: 100%;
    position: relative;

    img {
        width: auto !important;
        height: 100% !important;
    }

    &:after {
        content: ' ';
        display: block;
        mix-blend-mode: multiply;
        background-color: #f4f4f4;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.productCard-texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    max-width: 190px;
    height: 80%;
    margin: auto;
}

.productCard-title {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: #404040;
    text-transform: uppercase;
    margin-bottom: 1em;
}

.productCard-text {
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.5;
    color: #9d9d9d;
}

.productCard-button {
    @extend .button-greyUppercase;
    margin-top: auto;
    padding: 0.6em 1.1em;
}

@include media-breakpoint-down(lg) {
    .productCard-link { height: 250px; }
}

@include media-breakpoint-down(md) {
    .productCard-image {
        width: 50%;
        height: auto;
        flex: 0 0 auto;

        img {
            width: 100% !important;
            height: auto !important;
        }
    }

    .productCard-texts {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: none;
    }
}

@include media-breakpoint-down(xs) {
    .productCard-link {
        display: block;
        height: auto;
    }

    .productCard-image { width: 100%; }
}
