
.aboutFooter {
    text-align: center;
    margin: 100px 0;
}

.aboutFooter-content { display: inline-block; }

.aboutFooter-title {
    font-family: Oswald;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.7px;
    color: #14529c;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.aboutFooter-titleBold {
    display: block;
    font-weight: 400;
}

.aboutFooter-subtitle {
    font-family: Oswald;
    font-size: 27px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.8px;
    color: $main-color;
    text-transform: uppercase;
    border-top: 2px solid $main-color;
    padding-top: 30px;
}

@include media-breakpoint-down(xs) {
    .aboutFooter { margin: 50px 0; }

    .aboutFooter-title { font-size: 16px; }

    .aboutFooter-subtitle { font-size: 21px; }
}
