
.mosaic {
    padding-top: 55px;
    margin: 0 0 55px;

    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
}

.mosaic-element {
    width: 48%;

    &:nth-child(odd) {
        float: left;
        clear: left;
        text-align: right;
    }

    &:nth-child(even) {
        float: right;
        clear: right;
        text-align: left;
    }

    .mosaic-element + & { margin-top: 110px; }

    .mosaic-element-wideImage + & { margin-top: 250px; }
}

.mosaic-elementImage {
    display: block;
    background: #efefef;

    img { width: 100%; }

    .mosaic-element-wideImage & { width: 135%; }

    + * {
        margin-top: -50px;
        padding-top: 105px;
        position: relative;
        z-index: 1;

        .mosaic-element:nth-child(odd) & {
            padding-right: 29px;
            border-right: 1px solid $main-color;
            margin-right: 29px;
        }

        .mosaic-element:nth-child(even) & {
            padding-left: 29px;
            border-left: 1px solid $main-color;
            margin-left: 29px;
        }
    }
}

.mosaic-elementTitle {
    font-family: Oswald;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: #404040;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin-bottom: 0;
}

.mosaic-elementText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    color: #404040;
    width: 71%;

    .mosaic-element:nth-child(odd) & {
        padding-right: 29px;
        border-right: 1px solid $main-color;
        margin-right: 29px;
        margin-left: auto;
    }

    .mosaic-element:nth-child(even) & {
        padding-left: 29px;
        border-left: 1px solid $main-color;
        margin-left: 29px;
    }

    > *:last-child { margin-bottom: 0; }
}

@include media-breakpoint-down(lg) {
    .mosaic-elementText { width: 90%; }
}

@include media-breakpoint-down(md) {
    .mosaic-element { width: 49%; }

    .mosaic-elementTitle,
    .mosaic-elementText {
        .mosaic-element:nth-child(odd) & {
            padding-right: 10px;
            margin-right: 10px;
        }

        .mosaic-element:nth-child(even) & {
            padding-left: 10px;
            margin-left: 10px;
        }
    }

    .mosaic-elementTitle {
        font-size: 20px;
        padding-top: 70px !important;
    }
}

@include media-breakpoint-down(sm) {
    .mosaic-element {
        width: 100%;

        .mosaic-element + & { margin-top: 50px; }
    }

    .mosaic-element-wideImage .mosaic-elementImage { width: 100%; }
}

@include media-breakpoint-down(xs) {
    .mosaic-elementText { font-size: 13px; }
}
