@font-face {
  font-family: 'icomoon';
  src:  url($common_dir + '/fonts/icomoon.eot?6c2jzd');
  src:  url($common_dir + '/fonts/icomoon.eot?6c2jzd#iefix') format('embedded-opentype'),
    url($common_dir + '/fonts/icomoon.woff2?6c2jzd') format('woff2'),
    url($common_dir + '/fonts/icomoon.ttf?6c2jzd') format('truetype'),
    url($common_dir + '/fonts/icomoon.woff?6c2jzd') format('woff'),
    url($common_dir + '/fonts/icomoon.svg?6c2jzd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
    position: relative;

    .path1:before { content: "\e915"; }
    .path2:before { content: "\e916"; }
    .path3:before { content: "\e917"; }
    .path4:before { content: "\e918"; }

    .path2,
    .path3,
    .path4 {
        position: absolute;
        top: 0;
        left: 0;
    }
}
.icon-email:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e914";
}
.icon-category:before {
  content: "\e90e";
}
.icon-mounting:before {
  content: "\e90f";
}
.icon-project-size:before {
  content: "\e910";
}
.icon-tilt-angle:before {
  content: "\e911";
}
.icon-social-email:before {
  content: "\e908";
}
.icon-social-facebook:before {
  content: "\e909";
}
.icon-social-instagram:before {
  content: "\e90a";
}
.icon-social-linkedin:before {
  content: "\e90b";
}
.icon-social-pinterest:before {
  content: "\e90c";
}
.icon-social-twitter:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e907";
}
.icon-triangle-right:before {
  content: "\e905";
}
.icon-triangle-left:before {
  content: "\e906";
}
.icon-location:before {
  content: "\e900";
}
.icon-published:before {
  content: "\e901";
}
.icon-reporting:before {
  content: "\e902";
}
.icon-type:before {
  content: "\e903";
}
.icon-cancel:before {
  content: "\e904";
}
.icon-nav-drop:before {
  content: "\e913";
}
.icon-i:before {
  content: "\e924";
}
.icon-marcom:before {
  content: "\e925";
}
