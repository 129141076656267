
.headerNews {
    display: flex;
    align-items: center;
    padding-top: 85px;
}

.headerNews-imgContainer {
    width: 66%;
    margin-left: auto;
}

.headerNews-img img {
    width: 100%;
    height: auto;
}

.headerNews-credits {
    text-align: right;
    font-family: Roboto;
    font-size: 9px;
    line-height: 1.22;
    letter-spacing: 1px;
    text-align: right;
    color: #636363;
    margin-top: 5px;
}

.headerNews-text {
    max-width: 270px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.725;
    color: #636363;
}

.headerNews-date:before,
.headerNews-title:after {
    content: '';
    position: absolute;
    display: block;
    width: 80%;
    height: 1px;
    background-color: $main-color;
}

.headerNews-date {
    display: block;
    margin-bottom: .5em;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: #8c8c8c;
    position: relative;
    padding-top: 20px;

    &:before { top: 0; }
}

.headerNews-title {
    position: relative;
    margin-bottom: 30px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.225em;
    letter-spacing: -0.03em;
    color: #393939;
    padding-bottom: 20px;

    &:after { bottom: 0; }
}

.blocNews { margin: 93px 0; }

@include media-breakpoint-down(md) {
    .headerNews-imgContainer { width: 60%; }
}

@include media-breakpoint-down(sm) {
    .headerNews { display: block; }

    .headerNews-text { max-width: 100%; }

    .headerNews-title { font-size: 30px; }

    .headerNews-imgContainer { width: 100%; }
}

@include media-breakpoint-down(xs) {
    .headerNews-title { font-size: 25px; }
}
