
body {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.57;
    color: #383b41;
    /*background: url($common_dir + "/images/site-background.jpg") center top no-repeat #f4f4f4 fixed;*/
    background-size: 100%;
}

#main {
    text-align: center;
}

.largeContainer {
    background: #fff;
    display: block;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    text-align: left;
}


.smallContainer {
    @include media-breakpoint-up(xl) { max-width: 980px; }
}

h1, h2, h3, h4, h5, h6 {
    font-size: $font-size-base;
    font-weight: 400;
    line-height: $line-height-base;
}

a { color: $body-color; }

img { display: block; }

.container {
    position: relative;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.title {
    margin: 1.8em 0 0.4em;
    font-size: 56px;
    font-weight: 100;
    line-height: 1;
    color: #000;

    @include media-breakpoint-down(md) { font-size: 50px; }
    @include media-breakpoint-down(sm) { font-size: 40px; }
    @include media-breakpoint-down(xs) {
        font-size: 35px;
        margin-top: 1em;
    }
}

.title-big {
    @extend .title;
    font-size: 90px;

    @include media-breakpoint-down(md) { font-size: 50px; }
    @include media-breakpoint-down(sm) { font-size: 40px; }
    @include media-breakpoint-down(xs) { font-size: 35px; }
}

.intro {
    max-width: 640px;
    margin-bottom: 35px;

    > *:last-child { margin-bottom: 0; }
}

.defaultContent {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.7;
    text-align: justify;
    color: #636363;
    counter-reset: defaultContentOrderedListItem;

    a { color: #14529c; }

    h2 {
        font-family: Oswald;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.4;
        letter-spacing: 0.7px;
        color: #14529c;
        text-transform: uppercase;
        margin-top: 2.5em;
        text-align: left;

        @include media-breakpoint-down(xs) { font-size: 18px; }
    }

    h3 {
        font-size: 16px;
        line-height: 1.6;
        color: #404040;
        text-align: left;
    }

    ol, ul { padding-left: 0; }

    ol {
        list-style-type: none;

        li {
            position: relative;
            padding-left: 35px;
            margin-bottom: 10px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    & > ol > li:before {
        content: counter(defaultContentOrderedListItem) ".";
        counter-increment: defaultContentOrderedListItem
    }

    li > ol { counter-reset: defaultContentOrderedListSubItem; }

    li > ol > li {
        padding-left: 40px;
        margin-bottom: 0;

        &:before {
            content: counter(defaultContentOrderedListItem) "." counters(defaultContentOrderedListSubItem, ".") ".";
            counter-increment: defaultContentOrderedListSubItem
        }
    }
}

.go_to_main { display: none; }

.footer-imarcom {
    position: relative;

    &:before { color: #009eff; }

    &:after {
        color: #000;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.btn { cursor: pointer; }

.parallax {
    position: relative;
    overflow: hidden;
    padding-bottom: 55%;

    img {
        position: absolute;
        height: 150%;
        top: -50%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.homeBackground {
    padding: 5% 0;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url($common_dir + "/images/background-home.jpg") center center no-repeat;
        background-size: cover;
        opacity: 0.4;
        z-index: 1;
    }

    .container { z-index: 2; }

    .totalSupportCta { margin-bottom: 0; }

    @include media-breakpoint-down(xs) {
        padding-top: 50px;
    }
}

.slideshow-wrapper {
    font-size: 0;
    padding: 0 5%;
    background: linear-gradient( to bottom, #252525, #252525 80%, #fff 80%, #fff);
}
    
.breadcrumb {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    color: #9d9d9d;
    background: none;
    margin-top: 5em;
    margin-bottom: 0;
    padding: 0.5em 0;
    display: inline-block;

    + .title { margin-top: 0; }

    &:hover { color: #9d9d9d; }
}

.fancybox-content { background: #252525 !important; }

.fancybox-caption__body {
    font-family: Oswald;
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0.9px;
    color: #acacac;
}
