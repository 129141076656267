
.list {
    margin: 55px 0;

    .mosaic + & { margin-top: 130px; }
}

.list-title {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.1;
    color: #404040;
    margin-bottom: 15px;
}

.list-elements {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.list-element {
    width: calc(33.33% - 20px);
    margin: 35px 10px 0;
    padding-left: 55px;
    padding-top: 6px;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        width: 37px;
        height: 37px;
        background: url($common_dir + "/images/list-check.svg") no-repeat 0 0;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.list-elementTitle {
    font-family: Oswald;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.6px;
    color: #404040;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.list-elementText {
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.5;
    color: #9d9d9d;
}

@include media-breakpoint-down(md) {
    .list-element { width: calc(50% - 20px); }
}

@include media-breakpoint-down(sm) {
    .list-elements { margin: 0 -5px; }

    .list-element {
        width: calc(50% - 10px);
        margin-left: 5px;
        margin-right: 5px;
    }
}

@include media-breakpoint-down(xs) {
    .list-elements { margin: 0; }

    .list-element {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
    }
}
