
.box_group {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0 15px;
    display: inline-block;
    width: 300px;
}

.box_group_default { }
