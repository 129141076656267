
.benefitsBanner {
    margin: 5% 0;
    background: #252525;
}

.benefitsBanner-link {
    background: url($common_dir + "/images/benefits-banner-background.jpg") no-repeat center right 426px;
    background-size: auto 100%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: 23px 0;

    &:hover { text-decoration: none; }
}

.benefitsBanner-leftText {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.7;
    letter-spacing: 0.5px;
    color: $main-color;
    text-transform: uppercase;
    margin-top: auto;
    max-width: 350px;
}

.benefitsBanner-rightContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 350px;
    padding: 6px 0 12px;
}

.benefitsBanner-rightText {
    font-family: Oswald;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.8;
    letter-spacing: 1.1px;
    text-align: right;
    color: #348ce0;
    text-transform: uppercase;
}

.benefitsBanner-button {
    @extend .button-mediumGold;
}

@include media-breakpoint-down(lg) {
    .benefitsBanner-link { background-position: center right 344px; }
}

@include media-breakpoint-down(md) {
    .benefitsBanner {
        background: url($common_dir + "/images/benefits-banner-background.jpg") no-repeat center right #252525;
        background-size: auto 100%;
    }

    .benefitsBanner-link {
        background: rgba(0, 0, 0, 0.5);
        padding-left: 5px;
        padding-right: 15px;
    }
}

@include media-breakpoint-down(sm) {
    .benefitsBanner-link {
        display: block;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .benefitsBanner-leftText {
        max-width: none;
        font-size: 25px;
        line-height: 1.4;
    }

    .benefitsBanner-rightContent {
        max-width: none;
        display: block;
        padding: 0;
    }

    .benefitsBanner-rightText {
        text-align: center;
        line-height: 1.4;
        margin: 10px 0;
    }
}

@include media-breakpoint-down(xs) {
    .benefitsBanner-leftText { font-size: 20px; }

    .benefitsBanner-button { font-size: 13px; }
}
