
.search { padding-bottom: 50px; }

.search-results {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
}

.search-title {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #14529c;
    text-transform: uppercase;
    margin-top: 44px;
    margin-left: 6px;
    margin-right: 6px;
    width: 100%;
}

.searchCard {
    width: calc(25% - 12px);
    margin: 6px;

    &:hover {
        text-decoration: none;

        .searchCard-surTitle,
        .searchCard-title {
            color: #fff;
            background: #323232;
        }
    }
}

.searchCard-imageContainer {
    padding-top: 100%;
    position: relative;
    mix-blend-mode: multiply;
    background: #efefef;
}

.searchCard-image {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    // max-width: 100%;
    // max-height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.searchCard-surTitle {
    font-family: Roboto;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 1.8px;
    color: #a6a6a6;
    text-transform: uppercase;
    padding: 13px 18px 0;
    background: #efefef;
    transition: background 0.3s, color 0.3s;
}

.searchCard-title {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
    color: #404040;
    padding: 0 18px 12px;
    background: #efefef;
    transition: background 0.3s, color 0.3s;
}

@include media-breakpoint-down(md) {
    .searchCard { width: calc(33.33% - 12px); }
}

@include media-breakpoint-down(sm) {
    .searchCard { width: calc(50% - 12px); }
}

@include media-breakpoint-down(xs) {
    .search-results {
        margin-left: 0;
        margin-right: 0;
    }

    .search-title {
        margin-left: 0;
        margin-right: 0;
    }

    .searchCard {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}
