
.darkForm {
    background: url($common_dir + "/images/background-header.png") center 75% #252525;
    background-size: 200%;
    padding: 70px 100px;
    margin-top: 70px;

    .button-background { width: 100%; }

    .form_category_title { color: #f4f4f4; }

    .form_category_subtitle {
        width: 100%;
        color: #f4f4f4;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        margin-top: 10px;

        p { margin-bottom: 5px; }
    }

    .form-group {
        margin-bottom: 8px;
        position: relative;
    }

    .form-control {
        border-color: #7a7a7a;
        background-color: #313131;
        color: #fff;
        height: 40px;
        padding-bottom: 0;
        padding-top: 0.7em;
    }

    textarea { min-height: 80px; }

    .form-check-label { color: #f4f4f4; }

    .publicForm-otherValue {
        position: relative;
        margin-top: 8px;
    }

    .publicForm-other:not(.publicForm-other-visible) { display: none; }

    .form_category_container-hidden { display: none; }

    @include media-breakpoint-down(xs) {
        .fakePlaceholder { line-height: 1; }
    }
}

.darkForm-title {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.8px;
    color: #d4ac55;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.publicForm-submit {
    @extend .button-background;
}

.contactForm/*,
.requestAQuoteForm*/ {
    position: relative;

    .publicForm { position: relative; }

    .form_category_container {
        .row,
        textarea { height: 100%; }

        &:last-child {
            position: relative;
            padding-bottom: 48px;
        }
    }

    .publicForm-submit {
        position: absolute;
        bottom: 8px;
        right: 0;
        width: calc(50% - 6px) !important;
    }
}

@include media-breakpoint-down(md) {
    .darkForm { padding: 50px; }
}

@include media-breakpoint-down(sm) {
    .darkForm-title { font-size: 40px; }
}

@include media-breakpoint-down(xs) {
    .darkForm { padding: 20px; }

    .darkForm-title { font-size: 30px; }

    .contactForm .publicForm-submit/*,
    .requestAQuoteForm .publicForm-submit*/ { width: 100% !important; }
}
